import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import InputCheckboxUnique from "../../../components/InputCheckboxUnique";
import InputEditorTiny from "../../../components/InputEditorTiny";
import InputSelect from "../../../components/InputSelect";
import InputTextarea from "../../../components/InputTextarea";
import { convertIntToMoney } from "../../../helpers/conversions";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import { hasPermission } from "../../../services/auth";

const paymentStatusOptions = [
    { label: 'Quitado', value: 'quitado' },
    { label: 'Parcialmente quitado', value: 'parcialmente-quitado' },
    { label: 'Não quitado', value: 'nao-quitado' }
];

const faturadoOptions = [
    { label: 'Faturado', value: 'faturado' },
    { label: 'Não faturado', value: 'nao-faturado' }
];

const shippingTypeOptions = [
    {label: 'Não informado', value: null},
    {label: 'CIF', value: 'CIF'},
    {label: 'FOB', value: 'FOB'}
];

const OrderDetailTab = ({
    order, 
    selectedStatus, 
    setSelectedStatus,
    selectedPagamentoStatus,
    setSelectedPagamentoStatus,
    selectedFaturadoStatus,
    setSelectedFaturadoStatus,
    financeComments,
    setFinanceComments,
    shippingTypeReal,
    setShippingTypeReal,
    shippingFeeReal,
    setShippingFeeReal,
    shippingTimeReal,
    setShippingTimeReal,
    paymentTermReal,
    setPaymentTermReal,
    paymentFormReal,
    setPaymentFormReal,
    billingCompanyCnpjReal,
    setBillingCompanyCnpjReal,
    shippingTypeConference,
    setShippingTypeConference,
    shippingFeeConference,
    setShippingFeeConference,
    shippingTimeConference,
    setShippingTimeConference,
    paymentTermConference,
    setPaymentTermConference,
    paymentFormConference,
    setPaymentFormConference,
    billingCompanyCnpjConference,
    setBillingCompanyCnpjConference
}) => {
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        getStatuses();
    }, []);

    const getStatuses = () => {
        api.get(`/status?filters[0][type][equal]=order`).then(res => {
            setStatuses(res.data.resources);
        });
    }

    const setOriginalShippingType = e => {
        e.preventDefault();
        setShippingTypeReal(order.shipping_type);
    }

    const setOriginalShippingFee = e => {
        e.preventDefault();
        let fee = order.shipping_consult ? 'R$ 0,00' : convertIntToMoney(order.shipping_fee, true);
        setShippingFeeReal(fee);
    }

    const setOriginalShippingTime = e => {
        e.preventDefault();
        setShippingTimeReal(order.shipping_time);
    }

    const setOriginalPaymentTerm = e => {
        e.preventDefault();
        setPaymentTermReal(order.payment_term);
    }

    const setOriginalPaymentForm = e => {
        e.preventDefault();
        setPaymentFormReal(order.payment_form);
    }

    const setOriginalBillingCompanyCnpj = e => {
        e.preventDefault();
        setBillingCompanyCnpjReal(order.billing_company_cnpj);
    }

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <Input label={`Código do pedido`} value={order?.code} disabled={true} />
                </div>
                <div className="col-3">
                    <Input label={`Responsável`} value={order?.admin_name} disabled={true} />
                </div>
                <div className="col-3">
                    <InputSelect
                        label={`Status do pedido`}
                        options={createOptions(statuses, 'name', 'id')}
                        value={selectedStatus}
                        change={setSelectedStatus}
                    />
                </div>
            </div>
            <h2 className="section-title">Cliente</h2>
            {order?.type === "PJ" &&
                <div className="row">
                    <div className="col-3">
                        <Input label={`CNPJ`} value={order?.customer_company_cnpj} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Razão social`} value={order?.customer_company_company_name} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Nome da empresa`} value={order?.customer_company_trade_name} disabled={true} />
                    </div>
                </div>
            }
            {order?.type === "PF" &&
                <div className="row">
                    <div className="col-3">
                        <Input label={`CPF`} value={order?.customer_cpf ?? ''} disabled={true} />
                    </div>
                    <div className="col-3">
                        <Input label={`Nome`} value={order?.customer_name} disabled={true} />
                    </div>
                </div>
            }
            <h2 className="section-title">Empresa de faturamento</h2>
            <div className="row">
                <div className="col-3">
                    <Input label={`CNPJ`} value={order?.billing_company_cnpj ?? ''} disabled={true} />
                </div>
                <div className="col-4">
                    <Input label={`Razão social`} value={order?.billing_company_company_name} disabled={true} />
                </div>
                <div className="col-4">
                    <Input label={`Nome da empresa`} value={order?.billing_company_trade_name} disabled={true} />
                </div>
            </div>
            <h2 className="section-title">Total</h2>
            <div className="row">
                <div className="col-3">
                    <Input label={`Total`} value={`R$ ${convertIntToMoney(order?.total)}`} disabled={true} />
                </div>
                {/* {hasPermission('view_margem_order') &&
                    <>
                        <div className="col-3">
                            <Input label={`Margem orçamento`} value={`R$ ${convertIntToMoney(order?.margem_orcamento)}`} disabled={true} />
                        </div>
                        <div className="col-3">
                            <Input label={`Margem real`} value={`R$ ${convertIntToMoney(order?.margem_real)}`} disabled={true} />
                        </div>
                        <div className="col-3">
                            <Input label={`Diferença de margem`} value={`${convertIntToMoney(order?.margem_dif, true)}`} disabled={true} />
                        </div>
                    </>
                } */}
            </div>
            {/* <div className="row">
                <div className="col-6">
                    <h2 className="section-title">Conferência</h2>
                    <div className="order-conference">
                        <div className="row">
                            <div className="col-4">
                                <Input label={`CNPJ do faturamento`} value={order?.billing_company_cnpj} disabled={true} />
                            </div>
                            <div className="col-5">
                                <div className="input-group-with-link">
                                    <Input label={`CNPJ do faturamento real`} 
                                        value={billingCompanyCnpjReal} 
                                        change={setBillingCompanyCnpjReal} 
                                        mask={`convertToCnpj`}
                                        disabled={!hasPermission('edit_conference')}
                                    />
                                    {hasPermission('edit_conference') &&
                                        <a href="#" onClick={setOriginalBillingCompanyCnpj}>Preencher com valor original</a>
                                    }
                                </div>
                            </div>
                            <div className="col-3">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={billingCompanyCnpjConference} change={setBillingCompanyCnpjConference} />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input label={`Tipo do frete`} value={order?.shipping_type} disabled={true} />
                            </div>
                            <div className="col-5">
                                <div className="input-group-with-link">
                                    <InputSelect
                                        label={`Tipo do frete real`}
                                        options={shippingTypeOptions}
                                        value={shippingTypeReal}
                                        change={setShippingTypeReal}
                                        isDisabled={!hasPermission('edit_conference')}
                                    />
                                    {hasPermission('edit_conference') &&
                                        <a href="#" onClick={setOriginalShippingType}>Preencher com valor original</a>
                                    }
                                </div>
                            </div>
                            <div className="col-3">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={shippingTypeConference} change={setShippingTypeConference} />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input label={`Valor do frete`} value={order?.shipping_consult === true ? 'A consultar' : convertIntToMoney(order?.shipping_fee, true)} disabled={true} />
                            </div>
                            <div className="col-5">
                                <div className="input-group-with-link">
                                    <Input label={`Valor do frete real`} 
                                        value={shippingFeeReal} 
                                        change={setShippingFeeReal} 
                                        mask={`convertToMoney`}
                                        disabled={!hasPermission('edit_conference')}
                                    />
                                    {hasPermission('edit_conference') &&
                                        <a href="#" onClick={setOriginalShippingFee}>Preencher com valor original</a>
                                    }
                                </div>
                            </div>
                            <div className="col-3">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={shippingFeeConference} change={setShippingFeeConference} />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input label={`Prazo de entrega`} value={order?.shipping_time} disabled={true} />
                            </div>
                            <div className="col-5">
                                <div className="input-group-with-link">
                                    <Input 
                                        label={`Prazo de entrega real`} 
                                        value={shippingTimeReal} 
                                        change={setShippingTimeReal} 
                                        disabled={!hasPermission('edit_conference')}
                                    />
                                    {hasPermission('edit_conference') &&
                                        <a href="#" onClick={setOriginalShippingTime}>Preencher com valor original</a>
                                    }
                                </div>
                            </div>
                            <div className="col-3">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={shippingTimeConference} change={setShippingTimeConference} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="order-conference">
                        <div className="row">
                            <div className="col-4">
                                <Input label={`Cond. de pagto.`} value={order?.payment_term} disabled={true} />
                            </div>
                            <div className="col-5">
                                <div className="input-group-with-link">
                                    <Input 
                                        label={`Cond. de pagto. real`} 
                                        value={paymentTermReal} 
                                        change={setPaymentTermReal} 
                                        disabled={!hasPermission('edit_conference')}
                                    />
                                    {hasPermission('edit_conference') &&
                                        <a href="#" onClick={setOriginalPaymentTerm}>Preencher com valor original</a>
                                    }
                                </div>
                            </div>
                            <div className="col-3">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={paymentTermConference} change={setPaymentTermConference} />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input label={`Forma de pagto.`} value={order?.payment_form} disabled={true} />
                            </div>
                            <div className="col-5">
                                <div className="input-group-with-link">
                                    <Input 
                                        label={`Forma de pagto. real`} 
                                        value={paymentFormReal} 
                                        change={setPaymentFormReal}
                                        disabled={!hasPermission('edit_conference')}
                                    />
                                    {hasPermission('edit_conference') &&
                                        <a href="#" onClick={setOriginalPaymentForm}>Preencher com valor original</a>
                                    }
                                </div>
                            </div>
                            <div className="col-3">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={paymentFormConference} change={setPaymentFormConference} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <h2 className="section-title">Financeiro</h2>
                    <div className="order-finance">
                        <div className="row">
                            <div className="col-6">
                                <InputSelect
                                    label={`Pedido quitado`}
                                    options={paymentStatusOptions}
                                    value={selectedPagamentoStatus}
                                    change={setSelectedPagamentoStatus}
                                />
                            </div>
                            <div className="col-6">
                                <InputSelect
                                    label={`Faturado`}
                                    options={faturadoOptions}
                                    value={selectedFaturadoStatus}
                                    change={setSelectedFaturadoStatus}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <InputEditorTiny
                                    label={`Observações`}
                                    value={financeComments}
                                    change={setFinanceComments}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            
        </>
    );
} 

export default OrderDetailTab;