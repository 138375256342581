import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import DotStatus from "../../components/DotStatus";
import BoxTabs from "../../components/BoxTabs";
import Modal from "../../components/Modal";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import Table from "../../components/Table";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { Loading } from "../../components/Loading";
import { createOptions } from "../../helpers/selects";
import states from "../../seeds/states";
import axios from "axios";
import { toast } from "react-toastify";
import { convertIntToMoney, convertMoenyToInt, convertPercentageToFloat, convertPercentageToInt } from "../../helpers/conversions";
import { convertToPercentage } from "../../helpers/masks";
import { ModalDelete } from "../../components/ModalDelete";
import ModalCreateQuote from "../Quote/ModalCreateQuote";
import moment from "moment";
import origins from "../../seeds/origins";
import { getData, hasPermission, hasPlanFeature } from "../../services/auth";
import { ModalCreateCustomer } from "../../components/ModalCreateCustomer";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";

const types = [
    {label: 'Fornecedor de gravação', value: 'customization'},
    {label: 'Fornecedor de produto', value: 'product'},
    {label: 'Cliente', value: 'customer'},
    {label: 'Faturamento', value: 'billing'}
];

const QuotesTab = ({quotes}) => {
    const [data, setData] = useState({header: [], rows: []});

    useEffect(() => {
        generateData();
    }, []);

    const generateData = () => {
        const header = ['Código', 'Data da solicitação', 'Status'];
        const rows = [];
        
        quotes.map(quote => {
            rows.push({
                data: [
                    quote.code,
                    moment(quote.created_at).format('DD/MM/YYYY HH:mm'),
                    <>
                        <DotStatus color={quote.status_color} size={15} />
                        {quote.status_name}
                    </>
                ],
                to: `/quote/edit/${quote.id}`
            });
        });

        setData({header, rows});
    }

    return (
        <Table header={data.header} rows={data.rows} />
    );
}

const OrdersTab = ({orders}) => {
    const [data, setData] = useState({header: [], rows: []});

    useEffect(() => {
        generateData();
    }, []);

    const generateData = () => {
        const header = ['Código', 'Data da solicitação', 'Status'];
        const rows = [];
        
        orders.map(order => {
            rows.push({
                data: [
                    order.code,
                    moment(order.created_at).format('DD/MM/YYYY HH:mm'),
                    <>
                        <DotStatus color={order.status_color} size={15} />
                        {order.status_name}
                    </>
                ],
                to: `/order/edit/${order.id}`
            });
        });

        setData({header, rows});
    }

    return (
        <Table header={data.header} rows={data.rows} />
    );
}

const CompanyDetailsTab = ({
    admins, 
    company, 
    type, 
    setType, 
    admin, 
    setAdmin,
    tradeName,
    setTradeName,
    companyName,
    setCompanyName,
    cnpj,
    setCnpj,
    taxPercentage,
    setTaxPercentage,
    zipCode,
    setZipCode,
    street,
    setStreet,
    number,
    setNumber,
    complement,
    setComplement,
    district,
    setDistrict,
    city,
    setCity,
    state,
    setState,
    ie,
    setIe,
    phone,
    setPhone,
    origin,
    canViewPrice,
    setCanViewPrice,
    setOrigin,
    tinyAPIToken,
    setTinyAPIToken
}) => {
    return (
        <div>
            <h2 className="section-title">Dados cadastrais</h2>
            <div className="row">
                <div className="col-2">
                    <Input type={`text`} label={`Código`} value={company.code} disabled={true} />
                </div>
                <div className="col-4">
                    <InputSelect label={`Marcadores`} options={types} value={type} change={setType} isMulti={true} />
                </div>
                {type.includes('customer') &&
                    <div className="col-3">
                        <InputSelect label={`Responsável`} options={createOptions(admins, 'name', 'id', true)} value={admin} change={setAdmin} />
                    </div>
                }
                <div className="col-3">
                    <InputSelect label={`Onde nos conheceu`} options={origins} value={origin} change={setOrigin} />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <Input type={`text`} label={`CNPJ`} value={cnpj} change={setCnpj} mask={`convertToCnpj`} />
                </div>
                <div className="col-4">
                    <Input type={`text`} label={`Nome da empresa`} value={tradeName} change={setTradeName} />
                </div>
                <div className="col-4">
                    <Input type={`text`} label={`Razão social`} value={companyName} change={setCompanyName} />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <Input type={`text`} label={`IE`} value={ie} change={setIe} />
                </div>
                <div className="col-4">
                    <Input type={`text`} label={`Telefone`} value={phone} change={setPhone} mask={`convertToPhone`} />
                </div>
           
                {type.includes('billing') &&
                    <div className="col-4">
                        <Input type={`text`} label={`Imposto`} value={taxPercentage} change={value => setTaxPercentage( convertToPercentage(value) )} />
                    </div>
                }
            </div>

            {hasPlanFeature('price_display') && getData().price_display_option === 'only_approved_can_view' &&
                <div style={{ marginBottom: 40}}>
                    <h2 className="section-title">Exibição de preços</h2>
                    <InputCheckboxUnique
                        label={`Pode ver preços no site`}
                        checked={canViewPrice} 
                        change={setCanViewPrice} 
                    />
                </div>
            }
            {hasPlanFeature('integration_tiny') && type.includes('billing') &&
                <>
                    <h2 className="section-title">Integrações</h2>
                    <div className="row">
                        <div className="col-4">
                            <InputTextarea type={`text`} label={`Tiny API Token`} value={tinyAPIToken} change={setTinyAPIToken} />
                        </div>
                    </div>
                </>
            }

            <h2 className="section-title">Endereço</h2>
            <div className="row">
                <div className="col-3">
                    <Input type={`text`} label={`CEP`} value={zipCode} change={setZipCode} mask={`convertToCep`} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Input type={`text`} label={`Logradouro`} value={street} change={setStreet} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Número`} value={number} change={setNumber} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Complemento`} value={complement} change={setComplement} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <InputSelect label={`Estado`} options={states} value={state} change={setState} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Cidade`} value={city} change={setCity} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Bairro`} value={district} change={setDistrict} />
                </div>
            </div>
        </div>
    );
}

const ContactsTab = ({company, getCompany}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showCreate, setShowCreate] = useState(false);

    const [customerId, setCustomerId] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [customerToDelete, setCustomerToDelete] = useState(null);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    const updateContact = () => {
        setLoading(true);
        api.put(`/customer/${customerId}`, {
            name,
            phone,
            email
        }).then(res => {
            toast.success('Pessoa atualizada com sucesso');
            getCompany();
            setShowEdit(false);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const customerStored = customer => {
        setLoading(true);
        getCompany();
        setShowEdit(false);
    }

    const editContact = customer => {
        navigate(`/customer/edit/${customer.id}`);
    }

    const clean = () => {
        setCustomerId('');
        setName('');
        setPhone('');
        setEmail('');
    }

    const header = ['Nome', 'E-mail', 'Telefone', 'Empresa'];
    const rows = [];
    company.customers.map(customer => {
        let companies = customer.companies.map(company => company.trade_name).join(', ');
        rows.push({
            data: [customer.name, customer.email, customer.phone, companies],
            action: () => editContact(customer)
        });
    });

    const deleteCustomer = () => {
        setLoading(true);
        api.delete(`/customer/${customerToDelete}`).then(res => {
            setCustomerToDelete(null);
            getCompany();
        }).catch(error => {
            setLoading(false);
            renderError(error);
        });
    }

    return (
        <>
            
            <Modal
                title={`Editar pessoa`}
                show={showEdit}
                close={() => setShowEdit(false)}
                footer={
                    <>
                        <Button 
                            type={`secondary`} 
                            size={`small`}
                            svg={`delete`} 
                            text={`Excluir`} 
                            loading={loading} 
                            action={() => setCustomerToDelete(customerId)}
                        />
                        <Button 
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateContact}
                            loading={loading}
                        />
                    </>
                }
            >
                <div className="section">
                     <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} change={setName} value={name} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col-6">
                            <Input type={`email`} label={`E-mail`} change={setEmail} value={email} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Telefone`} change={setPhone} value={phone} mask={`convertToCellphone`} />
                        </div>
                    </div>                 
                </div>
            </Modal>
            <ModalCreateCustomer
                show={showCreate}
                close={() => setShowCreate(false)}
                customerStored={customerStored}
                companies={[company.id]}
                type={company.type}
            />
            <ModalDelete 
                show={customerToDelete !== null}
                action={deleteCustomer}
                close={() => setCustomerToDelete(null)}
                loading={loading}
            />
            {hasPermission('create_customer') &&
                <Button type={`secondary`} size={`small`} text={`Adicionar pessoa`} svg={`plus-blue`} action={() => setShowCreate(true)} />
            }
            <br />
            <Table header={header} rows={rows} loading={loading} />
        </>
    );
}

export const CompanyEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [admins, setAdmins] = useState([]);
    const [tabs, setTabs] = useState([]);

    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const [tradeName, setTradeName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [cnpj, setCnpj] = useState("");

    const [phone, setPhone] = useState("");
    const [ie, setIe] = useState("");

    const [taxPercentage, setTaxPercentage] = useState('0,0000 %');

    const [canViewPrice, setCanViewPrice] = useState(false);

    const [zipCode, setZipCode] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [tinyAPIToken, setTinyAPIToken] = useState("");

    const [origin, setOrigin] = useState("");

    const [type, setType] = useState([]);
    const [admin, setAdmin] = useState(null);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [showCreateQuote, setShowCreateQuote] = useState(false);

    useEffect(() => {
        getAdmins();
        getCompany();
    }, []);

    useEffect(() => {
        getAddress();
    }, [zipCode]);

    const deleteCompany = () => {
        setLoading(true);

        api.delete(`/company/${id}`).then(res => {
            navigete(`/company/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getAddress = () => {
        let convertedZipCode = zipCode.replace('-', '');
        if(convertedZipCode.length === 8){
            axios.get(`https://viacep.com.br/ws/${convertedZipCode}/json/`).then(res => {
                let address = res.data;
                setStreet(address.logradouro);
                setDistrict(address.bairro);
                setCity(address.localidade);
                setState(address.uf);
            })
        }
    }

    const getAdmins = () => {
        api.get(`/list`).then(res => {
            setAdmins(res.data.resources);
        });
    }

    const getCompany = () => {
        setLoading(true);

        api.get(`/company/${id}`).then(res => {
            console.log(res);
            const resource = res.data.resource;

            setCompany(resource);
            setAdmin(resource.admin_id);
            setType(resource.type);
            let toTabs = [
                'Detalhes da empresa',
                'Pessoas',
                'Orçamentos',
                'Pedidos'
            ];

            setTabs(toTabs);

            setPhone(resource.phone);
            setIe(resource.ie);

            setTaxPercentage( convertToPercentage(resource.tax_percentage ? resource.tax_percentage.toString() : '0') );
            
            setCanViewPrice(resource.can_view_price);

            setTradeName(resource.trade_name ?? '');
            setCompanyName(resource.company_name ?? '');
            setCnpj(resource.cnpj ?? '');
            setZipCode(resource.zip_code ?? '');
            setStreet(resource.street ?? '');
            setNumber(resource.number ?? '');
            setComplement(resource.complement ?? '');
            setDistrict(resource.district ?? '');
            setCity(resource.city ?? '');
            setState(resource.state ?? '');

            setTinyAPIToken(resource.tiny_api_token ?? '');

            setOrigin(resource.origin ?? '');

        }).catch(error => {
            console.log(error)
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateCompany = () => {
        setLoading(true);
        api.put(`/company/${company.id}`, {
            ie,
            phone,
            trade_name: tradeName,
            company_name: companyName,
            cnpj,
            type: type,
            tax_percentage: convertPercentageToInt(taxPercentage),
            can_view_price: canViewPrice,
            zip_code: zipCode,
            street,
            admin_id: type.includes('customer') ? admin : null,
            number,
            district,
            city,
            state,
            complement,
            origin,
            tiny_api_token: tinyAPIToken ? tinyAPIToken.trim() : null
        }).then(res => {
            getCompany();
            toast.success('Empresa atualizada com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const finishCreation = () => {
        navigete(`/quote/list`);
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteCompany}
                close={() => setConfirmDelete(false)}
                loading={loading}
            />
            {showCreateQuote &&
                <ModalCreateQuote
                    close={() => setShowCreateQuote(false)}
                    finishCreation={finishCreation}
                    customerCompanyId={id}
                />
            }
            <PanelTemplate id="product-edit">
                <Loading show={loading} />
                {!loading &&
                    <BoxTabs
                        titles={tabs}
                        contents={[
                            <CompanyDetailsTab
                                company={company}
                                type={type}
                                setType={setType}
                                admins={admins}
                                admin={admin}
                                setAdmin={setAdmin}
                                tradeName={tradeName}
                                setTradeName={setTradeName}
                                companyName={companyName}
                                setCompanyName={setCompanyName}
                                cnpj={cnpj}
                                setCnpj={setCnpj}
                                taxPercentage={taxPercentage}
                                setTaxPercentage={setTaxPercentage}
                                zipCode={zipCode}
                                setZipCode={setZipCode}
                                street={street}
                                setStreet={setStreet}
                                number={number}
                                setNumber={setNumber}
                                complement={complement}
                                setComplement={setComplement}
                                district={district}
                                setDistrict={setDistrict}
                                city={city}
                                setCity={setCity}
                                state={state}
                                setState={setState}
                                ie={ie}
                                setIe={setIe}
                                phone={phone}
                                setPhone={setPhone}
                                origin={origin}
                                setOrigin={setOrigin}
                                canViewPrice={canViewPrice}
                                setCanViewPrice={setCanViewPrice}
                                tinyAPIToken={tinyAPIToken}
                                setTinyAPIToken={setTinyAPIToken}
                            />,
                            <ContactsTab
                                company={company}
                                getCompany={getCompany}
                            />,
                            <QuotesTab
                                quotes={company.budgets}
                            />,
                            <OrdersTab
                                orders={company.orders}
                            />
                        ]}
                    />
                }
                <PageFooter spaceBetween={true}>
                    <>
                        <div>
                            {hasPermission('edit_company') &&
                                <Button type={`primary`} size={`small`} svg={`save-white`} text={`Salvar alterações`} action={updateCompany} />
                            }
                            {hasPermission('create_budget') &&
                                <Button type={`secondary`} size={`small`} text={`Criar orçamento`} action={() => setShowCreateQuote(true)} />
                            }
                        </div>
                        {hasPermission('delete_company') && company?.company_integration === null &&
                            <Button type={`secondary`} size={`small`} svg={`delete-small`} text={`Excluir`} action={() => setConfirmDelete(true)} />
                        }
                    </>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default CompanyEdit;